import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  default_split_location: '',
  scan_keystroke_delay: 50,
  scan_keystroke_delay_default: 50,
  transfer_items_add_to: 'top',
  scan_min_length: 2,
  csv_limit: 100,
  csv_code_priority: 'sku',
  csv_include_zero_qty: false,
  csv_default_operation: 'add',
  default_adjustment_operation: 'add',
  current_quantity_name: 'on_hand',
  transfer_prefix: "T",
  _init: false,
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state, { payload = {} }) => {
      return { 
        ...initialState,
        ...payload, 
        _init: true
      }
    },
    setConfigValue: (state, { payload = {} }) => {
      let { key, value } = payload
      state[key] = value
    },
  },
});

export const { setConfig, setConfigValue } = configSlice.actions;

export const getConfig = (state) => state.config;


export default configSlice.reducer;
